import { isMobileDevice } from "@/utils";
import { createRouter, createWebHashHistory } from "vue-router";
import LoginView from "@/view/LoginView.vue";
import ChatView from "@/view/sentosa/ChatView.vue";
import ChatHistory from "@/view/sentosa/ChatHistory.vue";
import { useTokenStore } from "@/store/token";
const ChatMain = () => import("../view/ChatMain.vue");
// const ChatMainSdk = () => import("../view/ChatMainSdk.vue");
const SettingManager = () => import("../view/SettingManage/SettingManage.vue");
const SettingHome = () =>
    import("../view/SettingManage/vdbManage/VdbTableList.vue");
const BotSetting = () =>
    import("../view/SettingManage/vdbManage/taskDetail.vue");
const GeneralSetting = () =>
    import("../view/SettingManage/vdbManage/components/updateOrNewBot.vue");
const AdvanceSetting = () =>
    import("../view/SettingManage/vdbManage/components/advanceSetting.vue")
const CrawlingSetting = () =>
    import("../view/SettingManage/vdbManage/components/crawlSetting.vue");
const UploadSetting = () =>
    import("../view/SettingManage/vdbManage/components/fileUpload.vue");
const ChunkList = () =>
    import("../view/SettingManage/vdbManage/components/ChunkList/chunkList.vue");
const SourceCollectionList = () =>
    import("../view/SettingManage/resources/ResourceList.vue");

const SourceCollectionCreate = () =>
    import("../view/SettingManage/resources/ResourceEdit.vue");

const QAList = () =>
    import("../view/SettingManage/vdbManage/components/QaList/qaList.vue");

const TaskList = () =>
    import("@/components/task/TaskList.vue");

const Page404 = () =>
    import("../view/404.vue");

const ChatShare = () =>
    import("../view/ChatShare.vue");

const ChatShareMobile = () =>
    import("../view/mobile/ChatShareMobile.vue")



const routes = [
  {
    path: '/chatView',
    name: 'ChatView',
    component: ChatView
  },
  {
    path: '/',
    name: 'ChatHistory',
    component: ChatHistory
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView
  },
  {
    path: "/chatBot",
    name: "home",
    component: SettingManager,
    children: [
      {
        path: "",
        name: "setting",
        component: SettingHome
      },
      {
        path: "new",
        name: "New",
        component: GeneralSetting,
        props: true,
      },
      {
        path: ":botId/setting",
        component: BotSetting,
        props: true,
        children: [
          { path: "", redirect: { name: "General" } },
          {
            path: "general",
            name: "General",
            component: GeneralSetting,
            props: true,
          },
          {
            path: "advance",
            name: "Advance",
            component: AdvanceSetting,
            props: true
          },
          {
            path: "source_database",
            name: "Source Database",
            component: TaskList,
            props: true,
            children: [
              {
                path: "crawl",
                name: "Add Crawler",
                component: CrawlingSetting,
                props: true,
              },
              {
                path: "upload",
                name: "Add File",
                component: UploadSetting,
                props: true,
              },
            ],
          },
          {
            path: "source-collection", name: "Souece-Collection",
            props: true,
            component: SourceCollectionList
          },
          {
            path: "source-collection/new", name: "Souece-Collection-add",
            props: true,
            component: SourceCollectionCreate
          },
          {
            path: "source-collection/:collectionId/edit", name: "Souece-Collection-edit",
            props: true,
            component: SourceCollectionCreate
          },

          { path: "chunks", name: "Chunk", component: ChunkList, props: true },
          { path: "qa", name: "QA", component: QAList, props: true },
        ],
      },
    ],
  },
  {
    path: "/:botId/chat",
    name: "chatMain",
    component: ChatMain,
    props: true
  },
  {
    path: "/:botId/help_desk",
    name: "helpDesk",
    component: () => import("../view/HelpDesk.vue"),
    beforeEnter: (to, from, next) => {
      if (isMobileDevice()) {
        next({...to, name: "helpDesk_mobile" })
      } else {
        next()
      }
    }
  },
  {
    path:"/:botId/help_desk_mobile",
    name: "helpDesk_mobile",
    component: () => import("../components/mobile/BubbleHello.vue"),
    props: true,
  },
  {
    path: "/:botId/help_desk_mobile/:sessionId",
    name: "helpDesk_mobile_session",
    component: () => import("../view/mobile/MobileHelpDesk.vue"),
    props: true,
    beforeEnter: (to, from, next) => {
      if (isMobileDevice()) {
        next()
      } else {
        next({...to, name: "helpDesk" })
      }
    }
  },
  {
    path: "/404",
    name: "404",
    component: Page404,
  },
  {
    path: "/share/:id",
    name: "share",
    component: ChatShare,
    beforeEnter: (to, from, next) => {
      if (isMobileDevice()) {
        next({...to, name: "share_mobile"})
      } else {
        next()
      }
    }
  },
  {
    path: "/share_mobile/:id",
    name: "share_mobile",
    component: ChatShareMobile,
    beforeEnter: (to, from, next) => {
      if (isMobileDevice()) {
        next()
      } else {
        next({ ...to, name: "share"})
      }
    }
  },
  {
    path: "/sales_copilot",
    name: "salesCopilot",
    component: () => import("../view/salesCopilot/SalesCopilot.vue"),
    beforeEnter: (to, from, next) => {
      document.title="Sales Copilot"
      next()
    },
    beforeRouteLeave(to, from, next){
      document.title="Shopping GPT"
      next()
    },
    children: [
      {
        path: "",
        component: () => import("../view/salesCopilot/QueriesView.vue"),
      },
      {
        path: "statistics",
        component: () => import("../view/salesCopilot/StatisticsView.vue")
      }
    ]
  },
  {
    path: "/:botId/shop",
    name: "Shop",
    props: true,
    component: () => import("../view/mobile/MobileShop.vue"),
  },
  {
    path: "/:botId/product/:productId",
    name: "ProductDetail",
    props: true,
    component: () => import("../view/mobile/ProductView.vue")
  },
  {
    path: "/:botId/shopping_cart",
    name: "MyShoppingCart",
    props: true,
    component: () => import ("../view/mobile/ShoppingCart.vue")
  },
  {
    path: "/:botId/shopping_orders",
    name: "MyOrders",
    props: true,
    component: () => import("../view/mobile/ShoppingOrders.vue")
  },
  {
    path: "/:botId/order/:orderId/complete",
    name: "OrderComplete",
    props: true,
    component: () => import("../view/mobile/OrderComplete.vue")
  },
  {
    path: "/terms",
    name: "terms",
    component: () => import("../view/WizTerms.vue"),
  },
  {
    path: "/privacy",
    name: "privacy",
    component: () => import("../view/WizPrivacy.vue"),
  }
  // { path: "/chatMainSdk", name: "chatMainSdk", component: ChatMainSdk },
];
const router = createRouter({
  history: createWebHashHistory(),
  routes,
});


router.beforeEach(async (to, from,next) => {
  console.log(from)
  const tokenStore = useTokenStore()

  // 检查目标路径是否以 /setting/ 开头，且不包含前缀 chatBot
  if (to.path === '/new' || (to.path.includes('/setting/') ||to.path.includes('/setting') )&& !to.path.startsWith('/chatBot')) {
    // 将 chatBot 前缀加到路径前
    next({ path: `/chatBot${to.fullPath}` });
  } else {
    next();  // 不需要修改，继续跳转
  }

  if (to.name !== 'ChatView' && localStorage.getItem('hasVisitedChatView') === 'true') {
    localStorage.clear()
    sessionStorage.clear()
  }

  // 跳过 chatView 路由的身份验证
  if (to.name === 'ChatView') {
    localStorage.setItem('hasVisitedChatView', 'true')
    return true  // 直接允许进入 ChatView 页面，不做身份验证
  }

  if (to.name !== 'Login' && to.name !== 'terms' && to.name !== 'privacy' && !tokenStore.getAuthenticated) {
    return { name: 'Login', query: { 'to':btoa(to.path) }}
  }
  return true
})



export default router;
