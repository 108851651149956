import axios from 'axios';
import moment from 'moment/moment';

export function copyToClipboard(stringValue) {
  try {
    const textarea = document.createElement("textarea");
    textarea.value = stringValue;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export function copyToClipboardV2(value) {
  const clipboard = navigator.clipboard
  if (!clipboard) {
    return new Promise((resolve) => {
      copyToClipboard(value)
      resolve()
    })
  }
  return clipboard.writeText(value)
}

export function dateFormat(dateFormat, date) {
  const map = {
    YYYY: date.getFullYear(),
    MM: ("0" + (date.getMonth() + 1)).slice(-2),
    DD: ("0" + date.getDate()).slice(-2),
    HH: ("0" + date.getHours()).slice(-2),
    mm: ("0" + date.getMinutes()).slice(-2),
    ss: ("0" + date.getSeconds()).slice(-2),
  };

  return dateFormat.replace(/YYYY|MM|DD|HH|mm|ss/gi, matched => map[matched]);
}

function isImageUrlValid(imageUrl, timeout) {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = function () {
      clearTimeout(timer);
      resolve(true);
    };

    img.onerror = function () {
      clearTimeout(timer);
      resolve(false);
    };

    const timer = setTimeout(function () {
      img.src = ""; // 设置src为空字符串会触发onerror
      resolve(false);
    }, timeout);

    img.src = imageUrl;
  });
}

export function getImageBase64WithAxios(imageUrl, timeout) {
  return new Promise((resolve) => {
    axios({
      url: imageUrl,
      method: 'GET',
      responseType: 'arraybuffer',
      timeout: timeout,
    })
      .then((response) => {
        clearTimeout(timer);
        if (response.status === 200) {
          const base64Url = Buffer.from(response.data, 'binary').toString('base64');
          resolve(base64Url);
        } else {
          resolve(null); // 返回null表示加载失败
        }
      })
      .catch((error) => {
        console.log(error)
        clearTimeout(timer);
        resolve(null); // 返回null表示加载失败
      });

    const timer = setTimeout(function () {
      resolve(null);
    }, timeout);
  });
}

class ImageLoader {
  constructor(callBack, target) {
    this.images = []
    this.callBack = callBack
    this.target = target

  }
  push(url) {
    if (!url)
      return
    this.images.push(url)
    if (this.callBack && this.images.length == this.target) {
      this.callBack([...this.images])
    }
  }
}

export const getTopKImages = (imageUrls, k, timeout, key) => {
  return new Promise((resolve) => {
    const callBack = (list) => {
      resolve(list)

    }
    const loader = new ImageLoader(callBack, k)
    const loaderWrapper = async (urlItem) => {
      const res = await isImageUrlValid(urlItem[key], timeout)
      if (res) {
        loader.push(urlItem)
      }
    }
    const promiseList = imageUrls.map(i => {
      return loaderWrapper(i)
    })
    Promise.all(promiseList).then(() => {
      resolve(loader.images.slice(0, k))
    })

  })
}

export const UTC2LocalTimeZone = (str, formate = '"YYYY/MM/DD HH/mm"') => {
  return moment.utc(str).local().format(formate)
}

export const local2UTCTimeZone = (str, inputFormate = "YYYY-MM-DD HH:mm", Targetformate = "YYYY-MM-DD HH:mm") => {
  return moment(str, inputFormate).utc().format(Targetformate)
}